import clsx from 'clsx';
import React, {
  ReactElement,
  useEffect,
  useRef,
  useState,
  VoidFunctionComponent,
} from 'react';

import './carousel.css';

type CarouselProps = {
  className?: string;
  children: ReactElement[];
};

const Carousel: VoidFunctionComponent<CarouselProps> = ({
  children,
  className,
}) => {
  const imageRefs = useRef<{
    [key: string]: HTMLDivElement;
  }>({});
  const [pauseCarousel, setPauseCarousel] = useState(false);
  const containerRef = useRef<HTMLDivElement>();

  useEffect(() => {
    let currentImageIdx = 0;
    const interval = setInterval(() => {
      const images = Object.values(imageRefs.current);

      if (pauseCarousel || !images.length) {
        return;
      }
      if (currentImageIdx >= images.length) {
        currentImageIdx = 0;
      }
      const currentImage = images[currentImageIdx];
      if (currentImage) {
        containerRef.current.scrollLeft = currentImage.offsetLeft;
      }
      currentImageIdx += 1;
    }, 2000);

    return () => {
      clearInterval(interval);
    };
  }, [imageRefs, pauseCarousel]);

  return (
    <div
      className={clsx('Carousel', className)}
      ref={containerRef}
      onMouseEnter={() => setPauseCarousel(true)}
      onMouseOver={() => setPauseCarousel(true)}
      onFocus={() => setPauseCarousel(true)}
      onMouseLeave={() => setPauseCarousel(false)}
    >
      {children
        .filter((child) => !!child.key)
        .map((child) => (
          <div
            key={child.key}
            ref={(ref) => {
              imageRefs.current[child.key] = ref;
            }}
            className="slide"
          >
            {child}
          </div>
        ))}
    </div>
  );
};

export default Carousel;
