import Layout from '@/components/Layout';
import SEO from '@/components/SEO';
import BlogPostPreview from '@/components/blog/BlogPostPreview';
import { formatTimeUntilNow } from '@/lib/date';
import { graphql, Link, PageProps } from 'gatsby';
import * as React from 'react';
import { FC } from 'react';
import RehypeReact from 'rehype-react';

import Carousel from '../components/rehype/Carousel';

const renderAst = new RehypeReact({
  createElement: React.createElement,
  components: {
    'oo-carousel': Carousel,
    // 'oo-cta-let-us-help-you': LetUsHelpYou,
    // 'oo-calendy': Calendy,
    // 'oo-features-pricing-table': FeaturesPricingTables,
  },
}).Compiler;

const BlogPost: FC<PageProps<any>> = ({ data: { blogPost } }) => {
  const {
    htmlAst,
    timeToRead,
    path,
    title,
    author,
    publishedDate,
    modifiedDate,
    image,
    metaDescription,
    relatedPosts,
  } = blogPost;

  return (
    <Layout>
      <SEO
        title={title}
        description={metaDescription}
        url={path}
        image={
          image
            ? {
                src: image.childImageSharp.gatsbyImageData.images.fallback.src,
                width: image.childImageSharp.gatsbyImageData.width,
                height: image.childImageSharp.gatsbyImageData.height,
              }
            : undefined
        }
        blogPost={{
          description: metaDescription,
          author: author.frontmatter.name,
          datePublished: publishedDate,
          dateModified: modifiedDate,
        }}
      />

      <div className="container m-auto">
        <div className="p-4 mb-20">
          <h1 className="header-1 text-left p-4 pt-24 lg:pt-32">{title}</h1>
          <section className="pl-4 text-gray-400 text-xl">
            <p>
              {timeToRead} min read{' '}
              {author && (
                <>
                  by{' '}
                  <Link to={author.frontmatter.path}>
                    {author.frontmatter.name}
                  </Link>
                </>
              )}
            </p>
            <p className="text-sm">
              published {formatTimeUntilNow(publishedDate)}, updated{' '}
              {formatTimeUntilNow(modifiedDate)}
            </p>
          </section>

          <section className="ml-4 mt-4 mb-12 lg:mb-24">
            <p>
              <Link className="text-primary" to="/">
                Home
              </Link>
              {' > '}
              <Link className="text-primary" to="/blog">
                Blog
              </Link>
              {' > '}
              {title}
            </p>
          </section>

          <section className="">
            <div className="generated-html">{renderAst(htmlAst)}</div>
          </section>

          <section className="mt-16">
            <div className="flex flex-row flex-wrap justify-between">
              {relatedPosts &&
                relatedPosts.map((post) => (
                  <BlogPostPreview
                    key={post.path}
                    className="max-2"
                    post={post}
                    displayAuthor
                  />
                ))}
            </div>
          </section>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query BlogPost($id: String!) {
    blogPost(id: { eq: $id }) {
      timeToRead
      htmlAst
      title
      path
      metaDescription
      author {
        frontmatter {
          path
          name
        }
      }
      relatedPosts {
        excerpt
        timeToRead
        title
        path
        publishedDate
        author {
          frontmatter {
            name
          }
        }
        image {
          childImageSharp {
            gatsbyImageData(
              width: 500
              # https://github.com/gatsbyjs/gatsby/issues/12552
              # placeholder: TRACED_SVG
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
      image {
        childImageSharp {
          gatsbyImageData(width: 1200, height: 630, formats: [AUTO])
        }
      }
      publishedDate
      modifiedDate
    }
  }
`;

export default BlogPost;
